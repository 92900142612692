import React from "react"
import tw, { styled } from "twin.macro"

const SectionStyles = styled.div`
  ${tw`relative`}
  opacity: 0.99;
  ${({ color }) => `background-color: ${color};`}

  &:before {
    ${tw`absolute top-0 left-0 w-full h-full`}

    content: "";
    background: ${({ bg }) => `url(${bg})`};
    background-repeat: repeat-y;
    background-size: 100% auto;
    background-color: transparent;
    background-position: center;
    opacity: ${({ opacity }) => opacity || "0.2"};
    z-index: -1;

    @media (max-width: 640px) {
      background-size: 250%;
      background-position: 30% 50%;
    }
  }

  &.demand360-ad {
    :before {
      @media (max-width: 640px) {
        background-position: top;
      }
    }
  }

  &.home {
    .centered-container {
      ${tw`pb-12 l:pb-48`}
    }
  }

  &.demand360-ad-measurement {
    &:before {
      background-repeat: "no-repeat";
      background-size: "cover";
    }

    .centered-container {
      ${tw`pb-16`}
    }
  }
`

const Gradient = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 16rem
  );

  &.page-404 {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 25%,
      rgba(0, 0, 0, 0) 75%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }

  &.measurement {
    background: none;
  }
`

const SectionBackground = ({
  bg,
  children,
  className,
  gradientClass,
  ...props
}) => {
  return (
    <SectionStyles bg={bg} className={`${className || ""}`} {...props}>
      <Gradient className={gradientClass}>{children}</Gradient>
    </SectionStyles>
  )
}

export default SectionBackground
