import React, { useState, useContext, useEffect } from "react"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import tw, { styled } from "twin.macro"
import { useTrackVisibility } from "react-intersection-observer-hook"
import { escape } from "lodash"

import { Profile, Title, Body } from "components/industry-profiles-large"
import ButtonLE from "components/button-le"
import { getProductId } from "utils/products"
import ConditionalLink from "components/conditional-link"
import ManualMoreLink from "components/manual-more-link"
import { getProductPath } from "utils/products"
import ActiveRouteContext from "context/active-route-context"
import { htmlFromSolution } from "utils/html-from-solution"

const Dot = styled.div`
  position: absolute;
  height: 12px;
  width: 12px;
  left: calc(50% - 6px);
  margin-left: 0px;
  margin-top: 20px;
  border-radius: 50%;
  background-color: ${({ color }) => color || "white"};
`

function buttonGradient(page) {
  const { buttonStart, buttonEnd } = page.extraData?.colors || {}

  return buttonStart && buttonEnd ? [buttonStart, buttonEnd] : null
}

function buildBody(page) {
  const { body, solutionsProduct } = page

  if (!solutionsProduct) {
    return body?.html || ""
  }

  return htmlFromSolution(solutionsProduct)
}

const ProductProfile = ({ page, link = false, productGroup = false }) => {
  const [hovered, setHovered] = useState(false)

  const { setSection, resetSection } = useContext(ActiveRouteContext)

  const [ref, { isVisible }] = useTrackVisibility({ threshold: 1 })

  const linkTo = link ? getProductPath(page.path) : null

  const sectionId = getProductId(page)

  const body = buildBody(page)

  useEffect(() => {
    if (isVisible) {
      setSection(sectionId)
    }
  }, [isVisible, sectionId])

  useEffect(() => {
    return resetSection
  }, [])

  return (
    <Profile
      className={`text-white font-body product ${
        productGroup ? "product-group" : ""
      }`}
      key={page.id}
      id={sectionId}
      ref={ref}
    >
      <div className="w-full l:w-5/12">
        <ConditionalLink
          to={linkTo}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <img className="image" src={page.heroImage.url} />
        </ConditionalLink>
      </div>
      <div className="hidden l:flex w-1/12 relative">
        <Dot color={page.extraData?.colors?.dot} />
      </div>
      <div className="w-full l:w-1/2">
        {linkTo && (
          <ManualMoreLink active={hovered} type="profile" className="mb-3">
            <ConditionalLink
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              to={linkTo}
            >
              <Title className="product-card">
                {page.headerTitle || page.title}
              </Title>
            </ConditionalLink>
          </ManualMoreLink>
        )}
        {!linkTo && (
          <Title className="product-profile">
            {page.headerTitle || page.title}
          </Title>
        )}
        <Body
          dangerouslySetInnerHTML={{
            __html: `<div>${body}</div>`,
          }}
          className="product"
        />
        {linkTo && (
          <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <ButtonLE
              gradient={buttonGradient(page)}
              to={linkTo}
              className="mt-6"
              color="purple"
            >
              Find Out More
            </ButtonLE>
          </div>
        )}
        {productGroup && (
          <ButtonLE
            gradient={buttonGradient(page)}
            to="/contact"
            className="mt-12"
            color="purple"
          >
            Contact Us
          </ButtonLE>
        )}
      </div>
    </Profile>
  )
}

export default ProductProfile
