import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import tw, { styled } from "twin.macro"

import ProductProfile from "components/product-profile"
import { getProducts } from "utils/products"

export default () => {
  const query = graphql`
    query ProductProfiles {
      gcms {
        pages(
          where: {
            path_in: [
              "/products/tv-demand"
              "/products/talent-demand"
              "/products/movie-demand"
            ]
          }
          orderBy: order_ASC
        ) {
          ...PageData
        }
      }
    }
  `

  const data = useStaticQuery(query)

  const pages = getProducts(data.gcms.pages)

  return (
    <>
      {pages.map((page) => (
        <ProductProfile key={page.id} page={page} link={true} />
      ))}
    </>
  )
}
